enum AccessibleFeatures {
    ATV_SPOT = 'ATV_SPOT',
    DIRECT_BILLING = 'DIRECT_BILLING',
    DOOH_LOCATION_REPORTING = 'DOOH_LOCATION_REPORTING',
    DV_VIEWABILITY = 'DV_VIEWABILITY',
    OTTO_PNTA_CUSTOM_FILTER = 'OTTO_PNTA_CUSTOM_FILTER',
    OTTO_PNTA_GEO_FILTER = 'OTTO_PNTA_GEO_FILTER',
    OTTO_PNTA_INTENT_FILTER = 'OTTO_PNTA_INTENT_FILTER',
    OTTO_PNTA_PURCHASE_FILTER = 'OTTO_PNTA_PURCHASE_FILTER',
    OTTO_PNTA_SOZIO_FILTER = 'OTTO_PNTA_SOZIO_FILTER',
    POSTAL_CODE_FILTER = 'POSTAL_CODE_FILTER',
    PTV = 'PTV',
    PTV_REPORTING = 'PTV_REPORTING',
    UNIQUE_IMPRESSION_REPORTING = 'UNIQUE_IMPRESSION_REPORTING',
    UNIQUE_IMPRESSIONS_REPORTING_NITRO_ID_OPTION = 'UNIQUE_IMPRESSIONS_REPORTING_NITRO_ID_OPTION',
    WEISCHER_AUDIENCES_TRACE_FILTER = 'WEISCHER_AUDIENCES_TRACE_FILTER',
    WEISCHER_AUDIENCES_DOOH_TV_BOOST_FILTER = 'WEISCHER_AUDIENCES_DOOH_TV_BOOST_FILTER',
    WEISCHER_AUDIENCES_CUSTOM_FILTER = 'WEISCHER_AUDIENCES_CUSTOM_FILTER',
    IAS_SITE_CONTEXT_CONTROL_AVOIDANCE_FILTER = 'IAS_SITE_CONTEXT_CONTROL_AVOIDANCE_FILTER',
    IAS_SITE_CONTEXT_CONTROL_TARGETING_FILTER = 'IAS_SITE_CONTEXT_CONTROL_TARGETING_FILTER',
    AUTOREPORTING = 'AUTOREPORTING',
    ODC_FILTERS = 'ODC_FILTERS',
}

export {AccessibleFeatures};

import {of, Observable} from 'rxjs';
import {Filter, IFilterProperties} from '../types/filter';
import {FilterTypes} from '../types/filter.types';
import {PeriodTypes} from '../types/period.types';
import {FrequencyCappingIds} from './frequency-capping-ids-enum';

interface IFrequencyCappingFilterProperties extends IFilterProperties {
    requests: number;
    time: number;
    period: PeriodTypes;
    ids: Array<FrequencyCappingIds>;
}

class FrequencyCappingFilter extends Filter<FilterTypes.FREQUENCY_CAPPING> {
    protected readonly _type: FilterTypes.FREQUENCY_CAPPING = FilterTypes.FREQUENCY_CAPPING;
    protected readonly _isMultiFilter: boolean = true;
    protected readonly _isAvailableAsBidFactor: boolean = false;
    protected readonly _isInventoriable: boolean = true;
    protected readonly _isAvailableForPreview: boolean = true;
    protected readonly _isInvertible: boolean = false;
    protected readonly props: IFrequencyCappingFilterProperties = this.getDefaultOptions();
    private _isValid: boolean = true;

    public updateValidStatus(newStatus: boolean): void {
        this._isValid = newStatus;
    }

    public isValid(): Observable<boolean> {
        if (
            !this.props.requests ||
            !this.props.time ||
            !this.props.period ||
            !this.props.ids
        ) {
            return of(false);
        }

        return of(this._isValid);
    }

    public canBeInventoried(): Observable<boolean> {
        return of(this.isInventoriable());
    }

    public isInverted(): Observable<boolean> {
        return of(false);
    }

    get requests(): number {
        return this.props.requests;
    }

    set requests(value: number) {
        this.props.requests = value;
    }

    get time(): number {
        return this.props.time;
    }

    set time(value: number) {
        this.props.time = value;
    }

    get period(): PeriodTypes {
        return this.props.period;
    }

    set period(value: PeriodTypes) {
        this.props.period = value;
    }

    get ids(): Array<FrequencyCappingIds> {
        return this.props.ids;
    }

    set ids(value: Array<FrequencyCappingIds>) {
        this.props.ids = value;
    }

    protected getDefaultOptions(): IFrequencyCappingFilterProperties {
        return {
            ...super.getDefaultOptions(),
            time: 0,
            requests: 0,
            period: PeriodTypes.Seconds,
            ids: [],
        };
    }
}

export {FrequencyCappingFilter, IFrequencyCappingFilterProperties};
